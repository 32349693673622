import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavItem } from 'src/lib/models/navigation.model';
import { AccountMe, AccountService } from '@iris/iris-authentication';
import { MetricsService } from 'src/lib/services/metrics/metrics.service';
import { NavItemMobile } from 'src/lib/models/navigation-mobile.models';
import { MenuServiceMobile } from 'src/lib/services/menu/menu-mobile.service';
import { ComplementsService } from 'src/lib/services/complements/complements.service';
import moment from 'moment';
import { UnsubscribeOnDestroyAdapter } from '@iris/iris-base';
import { MenuNewTag, ModuleFirstViewDate } from 'src/lib/models/moduleFirstViewDate.model';
import { Complement } from 'src/lib/services/complements/complements.types';
import { AccountsService } from 'src/lib/services/accounts/accounts.service';

declare var window: any;
const daysSinceModuleFirstViewToShowNewTag = 15;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent 
  extends UnsubscribeOnDestroyAdapter
  implements OnInit {
  @Input() showMobileMenu: boolean = false;
  @Input() me: AccountMe;
  @Input() groupName: string;
  @Output() logout: EventEmitter<any> = new EventEmitter();
  navItems$: NavItemMobile[];
  showIrisPayNewTag: Boolean = false;
  menusNewTag: MenuNewTag[] = [
    {
      name: 'IrisPay',
    },
    {
      name: 'IrisCard',
    },
  ];
  modulesPatch: ModuleFirstViewDate[] = [];


  constructor(
    private menuService: MenuServiceMobile,
    private metricsService: MetricsService,
    private complementsService: ComplementsService,
    private changeDetector: ChangeDetectorRef,
    private accountService: AccountService,
    private accountsService: AccountsService
    ) {
      super();
      window.refreshNavbar = () => {
        this.ngOnInit();
      };
    }

  ngOnInit() {
    this.menuService.getMenus().then((menus) => {
      this.addNewTagLabel();
      this.navItems$ = menus;
      this.changeDetector.detectChanges();
    });    
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  navigate(route: NavItem) {
    if (route.href === undefined || route.href === null) {
      return;
    }

    const customEvent = new CustomEvent('onChangeRoute', {
      detail: { route: route.href },
    });
    window.dispatchEvent(customEvent);
    this.showMobileMenu = false;
    this.metricsService.registrerMetrics(route?.title);
    this.updateFirstViewDate(route?.title);

    this.changeDetector.detectChanges();  
  }

  isActive(route: NavItem) {
    return window.location.pathname.startsWith(route.href);
  }

  @HostListener('window:onBackToMenuMobile', ['$event'])
  backToMenu(event$: any){
   this.toggleMobileMenu(); 
  }

  private addNewTagLabel() {
      const getMenusFirstViewDates = this.getMenusFirstViewDates();
      const getMenusCampaignDates = this.getMenusCampaignDates();
  
      Promise.all([getMenusFirstViewDates, getMenusCampaignDates]).then(() => {
        const menusToAddNewTag = this.menusNewTag.filter(
          menuNewTag =>
            this.isCampaignOn(menuNewTag) &&
            this.isFirstViewDateLessThan15Days(menuNewTag),
        );
  
        menusToAddNewTag.map(menuToAddNewTag => {
          const navItem = this.navItems$.find(
            navItem =>
              navItem.title.toUpperCase() == menuToAddNewTag.name.toUpperCase(),
          );
          if (navItem) navItem.highlight = true;
        });
        this.changeDetector.detectChanges();
      });
    }
  
    private getMenusCampaignDates() {
      return this.complementsService
        .get()
        .toPromise()
        .then((complements: Complement[]) => {
          complements.map(complement => {
            const menuNewTag = this.menusNewTag.find(
              menu => menu.name == complement.name,
            );
  
            if (menuNewTag) {
              menuNewTag.campaignStartDate = moment(
                complement.campaignStartDate,
                'YYYY-MM-DD',
              );
              menuNewTag.campaignEndDate = moment(
                complement.campaignEndDate,
                'YYYY-MM-DD',
              );
            }
          });
        });
    }
  
    private getMenusFirstViewDates() {
      return this.accountService
        .me()
        .toPromise()
        .then((r: any) => {
          if (r.moduleFirstViewDates) {
            this.modulesPatch = r.moduleFirstViewDates;
            r.moduleFirstViewDates.map((module: ModuleFirstViewDate) => {
              const menuNewTag = this.menusNewTag.find(
                menuNewTag =>
                  menuNewTag.name.toUpperCase() ==
                  module.moduleName.toUpperCase(),
              );
              if (menuNewTag) {
                menuNewTag.firstViewDate = module.firstViewDate;
              }
            });
          }
        });
    }
  
    private isCampaignOn(menuNewTag: MenuNewTag) {
      return (
        menuNewTag.campaignStartDate <= moment() &&
        menuNewTag.campaignEndDate > moment()
      );
    }
  
    private isFirstViewDateLessThan15Days(menuNewTag: MenuNewTag) {
      return (
        !menuNewTag.firstViewDate ||
        moment().diff(menuNewTag.firstViewDate, 'days') <
          daysSinceModuleFirstViewToShowNewTag
      );
    }

    private isNavigatingToModule(routeName: string) {
      return this.menusNewTag.find(
        menuNewTag => menuNewTag.name.toUpperCase() == routeName.toUpperCase(),
      );
    }

    private updateFirstViewDate(routeName: string) {   
        if (this.isNavigatingToModule(routeName)) {
          const unseenModules = this.menusNewTag.filter(
            m => m?.firstViewDate == undefined,
          );
          const moduleToUpdate = unseenModules.find(
            module => module.name.toUpperCase() == routeName.toUpperCase(),
          );
          if (moduleToUpdate) {
            this.modulesPatch = [
              ...this.modulesPatch,
              { moduleName: moduleToUpdate.name, firstViewDate: moment() },
            ];
            this.subs.add(
              this.accountsService
                .updateFirstViewDates(this.modulesPatch)
                .subscribe(),
            );
          }
        }
      }
}