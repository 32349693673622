import { Injectable } from '@angular/core';
import { SessionService } from '@iris/iris-authentication';
import { NavItemMobile, SubmenuMobile } from 'src/lib/models/navigation-mobile.models';
import { UserHasPermissionService } from '../common/user-has-permission.service';
import { RolePermissions } from './role.model';

@Injectable({ providedIn: 'root' })
export class MenuServiceMobile {
  menusMobile: NavItemMobile[] = [
    {
      href: '/dashboard',
      title: 'Dashboard',
      icon: 'img/menu/ic-resume.svg',
      hasAccess: false,
      permissions: [RolePermissions.COMPLETE_SUMMARY],
    },
    {
      title: 'Transactions',
      icon: 'img/menu/ic-transferencias.svg',
      href: '/transfer',
      hasAccess: false,
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
        RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS,
        RolePermissions.IDENTIFICATION_ACCOUNTS,
        RolePermissions.PAYMENTS_PAYROLL_MANUAL,
        RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
        RolePermissions.PAYMENTS_PAYROLL_BATCHES,
        RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
        RolePermissions.OPERATION_DETAILS,
        RolePermissions.CARDS_MANAGEMENT,
      ],
    },
    {
      title: 'IrisPay',
      icon: 'img/menu/ic-recaudos.svg',
      href: '/irispay',
      hasAccess: true,
      highlight: false,
    },
    {
      title: 'Company',
      icon: 'img/menu/ic-company-roles.svg',
      href: '/administration',
      hasAccess: true,
      permissions: [
        RolePermissions.AREAS_AND_TEAMS,
        RolePermissions.ROLE_AND_LIMITS,
        RolePermissions.USERS,
        RolePermissions.USER_LOGS,
        RolePermissions.PLANS,
        RolePermissions.IDENTIFICATION_TAGS,
        RolePermissions.DOCUMENTS_DOWNLOAD,
      ],
    },
    {
      title: 'Profile',
      href: '/user-management',
      icon: 'img/menu/ic-gesion-de-usuario.svg',
      hasAccess: false,
      permissions: [RolePermissions.NOTIFICATIONS, RolePermissions.USERS],
    },
  ];

  complementsSetFocus = new Map<string, string>([
    ['/complements/irispay', '/irispay'],
  ]);

  constructor(
    private hasPermissionService: UserHasPermissionService,
    private authService: SessionService,
  ) {
    this.handleUserPermissionsMobile(this.menusMobile);
  }

   handleUserPermissionsMobile(menu: NavItemMobile[]) {
     menu.map(menu => {
       if (menu.permissions?.length > 0) {
         menu.hasAccess = this.hasPermissionService.hasPermission(
           menu.permissions,
         );
       }
       menu.submenu?.forEach(async submenu => {
         if (submenu.permissions?.length > 0) {
           submenu.hasAccess = this.hasPermissionService.hasPermission(
             submenu.permissions,
           );
         }
       });
     });
   }
   
  async getMenus(): Promise<NavItemMobile[]> {
    await this.authService.refreshToken();
    this.handleUserPermissionsMobile(this.menusMobile);
    return this.menusMobile;
  }

  async getSubMenu(menuTitle: string): Promise<SubmenuMobile[]> {
    this.handleUserPermissionsMobile(this.menusMobile);
    return this.menusMobile.find(menu => menu.title === menuTitle).submenu;
  }
}
